import React from "react"

import SEO from "../components/seo"
import rond_color from "../assets/rond_color.svg"
import { Link } from "gatsby"

import Navigation from "../components/Navigation"


const plaiz100intro = () => (
    <div
        className="containerManifesto"
      >
        <div className=" container containerHome">
        <Navigation/>
        {/*<div className="rondTache row">
          <img src={rond} className="col-3 rond"/>
          <img src={tache} className="col-9 tache"/>
        </div>*/}
    <div>
    <SEO title="Plaiz - Plaiz 100 - Plaiz, le réseau social de la mode"/>
    <div>
    <h1 className="p1">Plaiz 100</h1>

    <h4
      style={styles.backToHomeLink}
    >
    <p className="textChangeGame"><br/>
    <b><i></i></b>
            <br/>
            <br/>
            <br/>
            <br/>
            Les Plaiz100 sont 100 visionnaires qui façonnent le futur de la mode et de la youth culture.<br/>
            <br/>
            L'un de nos objectif avec Plaiz est de présenter les talents émergents, les personnes qui auront un impact majeur sur leur génération, et de les présenter le plus tôt possible.
            <br/>
            Nous avons donc décidé de réaliser 100 interviews pour présenter des créateurs de marques, photographes, mannequins, stylistes, ou artistes en tout genre...
            <br/>
            <br/>
            <br/>
            Résultat visible <a target="#" href="https:instagram.com/plaiz_gang" className="textChangeGame"><b><u>ici</u></b></a> et bientôt sur notre <Link to={"/blog"} className="textChangeGame">blog</Link><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            Intéressé? Envoie nous un coucou à l'adresse <a target="#" href="mailto:hello@plaiz.io" className="textChangeGame"><b>hello@plaiz.io</b></a> <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </p>
    </h4>
    </div>
    </div>
    </div>
    </div>
)

export default plaiz100intro

const styles = {
  backToHomeLink: {
    color: "white",
  },
}